import React, { useState } from "react";
import "./home.css";

import Lottie from "lottie-react";
import AnimatonServer from "../../assets/image/animation/server.json";
import DdevOps from "../../assets/image/animation/ddevops.json";
import DevSecOps from "../../assets/image/animation/cyber.json";
import Team from "../../assets/image/animation/team.json";
import Whitelabel from "../../assets/image/animation/whitelabel.json";
import Offers from "../../assets/image/offer1.png";

import Button from "react-bootstrap/Button";
// import Card2 from "Components/ServiceCard";
import Testimonials2 from "Components/Testimonials2";
// import SlickCard from "Components/SlickCard";

import Tabs from "Components/Tab";
import Testcard1 from "Components/TestCard";
import Work from "Components/WorkExperience";
import Services from "pages/Services";
import { Link } from "react-router-dom";
import Bounce from "react-reveal/Bounce";

// import { Fade } from "react-reveal";
// import Testimonials from "Components/Testimonials";
import ContactUs from "pages/Contact";

import { Icon } from "@iconify/react";

import Carousel from "react-bootstrap/Carousel";
import { CarouselItem } from "react-bootstrap";
import Slickcardcall from "Components/slickCardCall";
import MetaTags from 'react-meta-tags';
import axios from 'axios';

function Home() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    inquiry: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://www.ddevops.com/sendmail.php', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.data.status === 'success') {
        alert('Email sent successfully');
      } else {
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }

  };

  return (
    <div>
      <MetaTags>
        <title>Dedicated DevOps Service | DDevOps Home</title>
        <meta name="description" content="Dedicated DevOps play significant roles in the application lifecycle, a one stop IT solutions for your Deployment Development and Operations." />
        <meta name="keywords" content="DevOps, Dedicated DevOps, Development and operations, DevSecOps, DevOps Solution, DDevOps" />
      </MetaTags>
      <div className="">
        <div className="container-fliud background-img">
          <Carousel controls={false} className="">
            <CarouselItem interval={2000}>
              <div className="container ">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="p-4">
                      <Bounce cascade>
                        <h1 className=" main-heading ">
                          <span className="heading-span">Engage</span>
                          <br />
                          in the Future.
                        </h1>
                      </Bounce>
                      <p className="font-p-size">
                        Dedicated DevOps for your Deployment, Development, and Operations
                      </p>
                    </div>
                    <div className="ms-4">
                      <a
                        href="https://youtu.be/Q_5hfx_Cd-E?si=oavr_GB8YzpBCNPY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button className="button-color button-color360">
                          Why DevOps?
                        </Button>
                      </a>
                      <a href="/Contact">
                        <Button className="button-2-color button-2-color375 button-2-color400 button-2-color412 button-2-color360">
                          Free Consultation
                        </Button>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 server400-width">
                    <Lottie
                      animationData={AnimatonServer}
                      loop={true}
                      style={{ width: "350" }}
                      className="animation-img-responsive375 animation-img-responsive400 animation-img-responsive412"
                    />
                  </div>
                </div>
              </div>
            </CarouselItem>

            <CarouselItem interval={2000}>
              <div className="container ">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="p-4">
                      <h1 className=" main-heading ">
                        <span className="heading-span">DDevOps</span>
                        <br />
                        Services.
                      </h1>
                      <p className="font-p-size">
                        Dedicated Development Operation For Your Continuous Advancement.
                      </p>
                    </div>
                    <div className="ms-4">
                      <a
                        href="https://youtu.be/Q_5hfx_Cd-E?si=oavr_GB8YzpBCNPY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button className="button-color">Why DevOps?</Button>
                      </a>
                      <a href="/Contact">
                        <Button className="button-2-color button-2-color375 button-2-color400 button-2-color412">
                          Free Consultation
                        </Button></a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Lottie
                      animationData={DdevOps}
                      loop={true}
                      className="margin-devops devops-responsive1440 animation-img-responsive375 animation-img-responsive400 animation-img-responsive412"
                    />
                  </div>
                </div>
              </div>
            </CarouselItem>

            <CarouselItem interval={2000}>
              <div className="container ">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="p-4">
                      <h1 className=" main-heading ">
                        <span className="heading-span">DevSecOps</span>
                        <br />
                        Services.
                      </h1>
                      <p className="font-p-size">
                        Get Yourself Protected From The Start And Enjoy A Secure
                        Journey.
                      </p>
                    </div>
                    <div className="ms-4">
                      <a
                        href="https://youtu.be/Q_5hfx_Cd-E?si=oavr_GB8YzpBCNPY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button className="button-color">Why DevOps?</Button>
                      </a>
                      <a href="/Contact">
                        <Button className="button-2-color button-2-color375 button-2-color400 button-2-color412">
                          Free Consultation
                        </Button></a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Lottie
                      animationData={DevSecOps}
                      loop={true}
                      className="devsecops-width devsecops-responsive1440 animation-img-responsive375 animation-img-responsive400 animation-img-responsive412"
                    />
                  </div>
                </div>
              </div>
            </CarouselItem>


            <CarouselItem interval={2000}>
              <div className="container ">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="p-4">
                      <h1 className=" main-heading ">
                        <span className="heading-span">Technology</span>
                        <br />
                        Partnership.
                      </h1>
                      <p className="font-p-size">
                        Get You A Hand With Custom IT Solutions And Joint
                        Technology Innovations.
                      </p>
                    </div>

                    <div className="ms-4">
                      <a
                        href="https://youtu.be/Q_5hfx_Cd-E?si=oavr_GB8YzpBCNPY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button className="button-color">Why DevOps?</Button>
                      </a>
                      <a href="/Contact">
                        <Button className="button-2-color button-2-color375 button-2-color400 button-2-color412">
                          Free Consultation
                        </Button></a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Lottie
                      animationData={Whitelabel}
                      loop={true}
                      className="whitelabe-width animation-img-responsive375 animation-img-responsive400 animation-img-responsive412"
                    />
                  </div>
                </div>
              </div>
            </CarouselItem>

            <CarouselItem interval={2000}>
              <div className="container ">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="p-4">
                      <h1 className=" main-heading ">
                        <span className="heading-span">Whitelabel</span>
                        <br />
                        Solutions.
                      </h1>
                      <p className="font-p-size">
                        Reach Out To Get Most Reliable And Secure Solutions For
                        Your IT Services.
                      </p>
                    </div>
                    <div className="ms-4">
                      <a
                        href="https://youtu.be/Q_5hfx_Cd-E?si=oavr_GB8YzpBCNPY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button className="button-color">Why DevOps?</Button>
                      </a>
                      <a href="/Contact">
                        <Button className="button-2-color button-2-color375 button-2-color400 button-2-color412">
                          Free Consultation
                        </Button></a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Lottie
                      animationData={Team}
                      loop={true}
                      className="team-width team-responsive1440 animation-img-responsive375 animation-img-responsive400 animation-img-responsive412"
                    />
                  </div>
                </div>
              </div>
            </CarouselItem>

          </Carousel>
        </div>
      </div>

      <div>
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 mt-5 mb-5 text-center heading-home">
              <h6>
                Strong Individuals Building An Invincible Team, Be A Part! Get
                Relaxed And Stay On Ease To <br />
                Focus More On Your Core Business.
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Testcard1 />
      </div>

      <div className="container-fluid web-deliver-logo ">
        <div className="container join-img-size">
          <div className="row ">
            <div className="what-you-get">
              <div className="col-lg-12 mt-5 mb-5 text-center heading-home ">
                <h1>What will you get if you join us</h1>
                <h6>
                  Get the best web hosting service at the price you can afford
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row heading-home get-text-center">
          <div className="col-lg-6 p-5 text-watermark">
            <strong className="service-headings">
              <Icon
                icon="carbon:calendar-settings"
                color="#00aeef"
                width="40"
                height="40"
                className="me-3"
              />
              WE GIVE A CARE
            </strong>
            <p className="mt-2 ms-lg-5">
              Beside the support we provide you with various tools to be able to{" "}
              <b />
              host and manage your websites.
              <b />
            </p>
            <br />
            <strong className="service-headings">
              <Icon
                icon="uil:cloud-database-tree"
                color="#00aeef"
                width="40"
                height="40"
                className="me-3"
              />
              TWEAK AS YOU WISH
            </strong>
            <p className="mt-2 ms-lg-5">
              Be able to customize your plan over time if needed so you pay              <b />
              only for what you use
            </p>
            <br />
            <strong className="service-headings">
              <Icon
                icon="grommet-icons:shield-security"
                color="#00aeef"
                width="40"
                height="40"
                className="me-3"
              />
              SECURITY AT ITS BEST
            </strong>
            <p className="mt-2 ms-lg-5">
              We are the most reliable service provider and ready to give you a
              helping hand <b />

              the fastest way possible 24/7
            </p>
          </div>

          <div className="col-lg-6">
            <img src={Offers} alt="dm" className="offers-width" />

          </div>
        </div>
      </div>

      <div>
        <Work />
      </div>

      <div className="">
        <div className="container ">
          <div className="row ">
            <div className="col-lg-12 mt-5 mb-5 text-center heading-home ">
              <h6>
                Join more than 10,000 Businesses using{" "}
                <span className="joint-span"> DDevOps </span> to delight
                customers and keep themselves <br />
                on a continuous path of advancement.
              </h6>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container ">
            <div className="row ">
              <div className="service-we-deliver">
                <div className="col-lg-12 mt-5 text-center heading-home ">
                  <h1>Service We Deliver</h1>
                  <h6>
                    Preparing your success, through truly prominent IT
                    solutions.
                  </h6>
                </div>
              </div>
              {/* <div>
                <Card2 />
              </div> */}
              <div className="container">
                <Slickcardcall />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Services />
      </div>

      <div>
        <Tabs />
      </div>

      <div>
        {/* <Testimonials /> */}
        <Testimonials2 />

      </div>

      <div>
        <form onSubmit={handleSubmit} className="contact-form">

          <div className='container homepage company-gradiant1'>
            <div className='row mb-5'>
              <div className='col-lg-7'>
                <h3 className="heading-company">We provide IT & TECH Services that vow
                  <span className='develop-span'> your success</span>
                </h3>

                <h5 className='mt-4 f_c'>DDevOps engages the best people for your team.</h5>

                <h3 className='mt-5 mb-3 book-text f_c'>Book a consultation to find <br />more about success.</h3>

                <div>
                  <table>
                    <tr>
                      <td>
                        <input className="form-control form-control-lg selector-border mb-3" type="text" id="name" placeholder="Full Name *" required onChange={handleChange} />
                      </td>
                      <td>
                        <input type="email" class="form-control-lg selector-border email-input mb-3 " id="email" placeholder="Email Address *" required onChange={handleChange} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input className="form-control form-control-lg selector-border" type="text" id="company" placeholder="Company/Website *" required onChange={handleChange} />
                      </td>
                      <td>
                        <select className="form-control-lg selector-width selector-width400" id="inquiry" required onChange={handleChange} >
                          <option>Your inquiry about</option>
                          <option>DevOps Services</option>
                          <option>SecOps Services</option>
                          <option>WordPress Development</option>
                          <option>Ecommerce Solution</option>
                          <option>Web & App Development</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <button className="btn button-background mt-3" type="submit" >Get A Free Consultation</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>


    </div>
  );
}

export default Home;
